/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
.cursorPointer{
    cursor: pointer
}
.toast-success {
    color: #ffffff !important;
}
ngb-datepicker {
    width: auto !important;
    padding: 0;
    z-index: 10 !important;
  }
.pac-container {
    z-index: 10000 !important;
}